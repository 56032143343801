import { useEffect, useRef, useState } from 'react';
import MindMap from 'simple-mind-map';
import Themes from 'simple-mind-map-plugin-themes'
import Toolbar from './MindMap/Toolbar';

Themes.init(MindMap)

export default function Mind(props){
  const ref = useRef(null);
  const [editor, setEditor] = useState();

  let mindMapInstance;
  useEffect(() => {
    const data = props.value
      ? props.value
      : {
          data: {
            text: props.rootName || 'Root',
          },
          children: [],
        };
        
    const defaultParams = {
      el: ref.current,
      readonly: props.disabled,
      data,
      layout: 'mindMap',
      enableAutoEnterTextEditWhenKeydown: true,
      nodeTextEditZIndex: 1000,
      nodeNoteTooltipZIndex: 1000,
      theme: 'dark7',
      lineStyle: 'curve',
    };

    mindMapInstance = new MindMap(defaultParams);
    mindMapInstance.on('data_change', (data) => {
      props.onChange(data);
    });

    setEditor(mindMapInstance);
  }, []);
  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
        <Toolbar editor={editor} />
    </div>
  );
};