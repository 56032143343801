import { Tag, Tooltip } from 'antd';
import styles from '../css/ConceptTags.module.scss';

export default function ConceptTags(props) {
    const { refTags, value, onChange, disabled } = props


    const handleChange = (changedValue, checked) => {
        if (disabled) return false
        
        if (checked) {
            onChange([...value, Number(changedValue)])
        } else {
            onChange(value.filter((i) => i !== Number(changedValue)))
        }
    }

    return (
        <>
            {
                refTags.map((tag) => (
                    <Tooltip title={tag.name}>
                        <Tag.CheckableTag
                            key={tag.value}
                            checked={value.includes(tag.value)}
                            onChange={(checked) => handleChange(tag.value, checked)}
                            className={styles.tag}
                        >
                            {tag.label}
                        </Tag.CheckableTag>
                    </Tooltip>
                  ))
            }
        </>
    )
}