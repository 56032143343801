

import React from 'react';
import { Button, Tabs } from 'antd';
import styles from '../css/ProjectCate.module.scss';
import projectStore from '../store/projectStore';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import ConceptDetail from './ConceptDetail';
import RefDetail from './RefDetail';

const ProjectCate = () => {

    const project = projectStore.project
    const refItems = project.projectRefs.map((item, index) => {
        return ({
            label: `Ref ${index + 1}`,
            children: <RefDetail projectRef={item} type='edit' disabled={project.step !== 0}/>,
            key: index.toString()
        })
    })

    const refTags = project.projectRefs.map((item, index) => {
        return ({
            label: `Ref ${index + 1}`,
            value: item.id,
            name: item.name
        })
    })

    const conceptItems = project.keyConcepts.map((item, index) => {
        return ({
            label: `Key concept ${index + 1}`,
            children: <ConceptDetail keyConcept={item} refTags={refTags} type='edit' disabled={project.isCompleted}/>,
            key: index.toString()
        })
    })

    const nextEnable = project.keyConcepts.every(keyConcept => !!keyConcept.title && keyConcept.subConcepts.every(subconcept => {
        return !!subconcept.title
    })) && !project.isCompleted

    return (
        <div>
            <div className={styles.container}>
            <div className={styles.left}>
                {
                    <div className={styles.leftContaier}>
                        <div className={styles.leftTop}>
                        <div className={styles.researchTopic}>
                            Research topic：
                            {project.researchTopic}
                        </div>
                        {
                            project.researchQuestions.map((_, index) => {
                                const questions = project.researchQuestions
                                return (
                                    <div className={styles.researchQuestion} key={index}>
                                        Research question {questions.length > 1 ? index + 1 : ''}：
                                        {questions[index]}
                                        {
                                            index === questions.length - 1 && project.step === 0 &&
                                            <div className={styles.addQuestionBtn}>
                                                <Button size='small' onClick={projectStore.addQuestion} icon={<PlusOutlined />}>Add a Research question</Button>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        </div>

                        <div className={styles.leftCenter}>
                            <Tabs
                                items={refItems}
                                activeKey={projectStore.refTabIndex}
                                onChange={(key) => {
                                    projectStore.refTabIndex = key
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
                <div className={styles.right}>
                    <div className={styles.rightContainer}>
                        <div className={styles.step1Right}>
                            <div className={styles.title}>
                                <Button disabled={project.isCompleted} onClick={projectStore.addKeyConcept} className={styles.addConcept} icon={<PlusOutlined />} loading={projectStore.loading}>Add a key concept</Button>
                            </div>
                            <div className={styles.content}>
                                <Tabs items={conceptItems} activeKey={projectStore.conceptTabIndex} onChange={(key) => projectStore.conceptTabIndex = key} className={styles.conceptTabs}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerContainer}>
                <div className={styles.footerLeft}>
                </div>
                <Button 
                    disabled={!nextEnable} 
                    type='primary' 
                    size='large' 
                    onClick={projectStore.submitConcepts}
                    className={styles.nextStep}
                    loading={projectStore.submitLoading}
                >Next step</Button>
                </div>
            </div>
        </div>
    )
}

export default observer(ProjectCate);