import React from 'react';
import  { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { observer } from "mobx-react";
import styles from '../css/RefDetail.module.scss';

function RefDetail(props) {
    
    const { projectRef, disabled } = props

    const type = props.type

    const onUpload = (info) => {
      if (info && info.file && info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info && info.file && info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        projectRef.docUrl = info.file.response.data.url
      } else if (info && info.file && info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }


    return (
      <div className={styles.body}>
        {
            type === 'edit' && 
            <div className={styles.upload}>
              <Upload
                defaultFileList={[
                  ...(projectRef && projectRef.docUrl ? [{uid: '1', status: 'done', name: projectRef.name, url: projectRef.docUrl}] : [])
                ]}
                maxCount={1}
                disabled={disabled}
                accept='.pdf,.doc,.docx'
                action={`/v1/project/uploadFile?refId=${projectRef.id}`}
                onChange={(info) => onUpload(info)}
              >
                { disabled ? null : <Button icon={<UploadOutlined />} className={styles.uploadBtn}>Upload a Docx file</Button>}
              </Upload>
              <div className={styles.uploadTxt}>Rename the file: Author (Year) Paper title (.docx/.doc)</div>
            </div>
        }
        
        <div className={styles.summary}>
          <div>*Summary<span className={styles.summaryDesc}>(Please include four aspects in the reference annotation: research topic/aim, methodology, findings, relevance to my study)</span></div>
            <textarea
              disabled={disabled}
              placeholder='Please input your summary here'
              className={styles.textarea}
              onChange={(e) => {
                projectRef.summary = e.target.value
              }}
              value={projectRef.summary}
            />
        </div>
      </div>
      
    )

  
}


export default observer(RefDetail);