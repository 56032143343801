import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import  { Popover, Modal } from 'antd';
import { observer } from "mobx-react";
import styles from '../css/TabBar.module.scss';
import logoLeft from '../img/logo-icon.png';
import logoRight from '../img/logo-text.png';
import arrow from '../img/arrow-down.png';
import userStore from '../store/userStore';


function TabBar(props) {
    
    // useEffect(() => {
    //   userStore.refresh()
    // }, [])

    const [openModal, setOpenModal] = useState(false)

    const navigate = useNavigate()
  
  
    const showModal = () => {
      setOpenModal(true)
    }
    
    const hideModal = () => {
      setOpenModal(false)
    }

    const handleHome = () => {
      navigate('/create-project')
    }

    const logout = () => {
      localStorage.removeItem('token')
      navigate('/login')
    }

    const { title, hideInfo, customLogo, step, showHome } = props
    // const userProfile = userStore.profile


    // if (!userProfile || userStore.loading) return <Loading />

    return (
      <div className={styles.body}>
        
        <div className={styles.left} onClick={handleHome}>
          <img src={logoLeft} className={styles.logoLeft} alt="logoLeft" />
          <img src={logoRight} className={styles.logoRight} alt="logoRight" />
        </div>

        {
          !hideInfo &&

          <div className={styles.right}>

            <Popover
                content={
                  <div>
                    <Link to='/my-account'><div className={styles.myAccount}>My account</div></Link>
                    <div className={styles.divider} />
                    <div className={styles.logOut} onClick={logout}>Log out</div>
                  </div>
                }
                arrow={false}
                trigger="click"
                placement="bottomLeft"
                overlayClassName={styles.pop}
              >
              <div className={styles.userInfo}>
                <div className={styles.userName}>{ userStore.profile?.username }</div>
                <img src={arrow} className={styles.userArrow} />
              </div>
            </Popover>

          </div>
        }

  
        <Modal
          open={openModal}
          centered
          maskClosable={true}
          width={'65vw'}
          destroyOnClose={true}
          style={{pointerEvents: 'auto', top: 20, marginBottom: 40}}
          // modalRender={() => {
          //   return(<ShowPoints hideModal={() => hideModal} userVersion={userStore.profile?.userVersion}/>)
          // }}
        >
        </Modal>

      </div>
    )

  
}


export default observer(TabBar);