import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Spin, message } from 'antd';
import { userLogin } from './utils/request';
import { Button } from 'antd';
import styles from './css/Login.module.scss';
import logoLeft from './img/logo-icon.png';
import logoRight from './img/logo-text.png';
import loginAccount from './img/login-account.png';
import loginPsw from './img/login-psw.png';
import beian from './img/beian.png';

export default function Login () {

        const navigate = useNavigate()

        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [loading, setLoading] = useState(false)

        const login = async () => {
            setLoading(true)
            try {
                const result = await userLogin({ email, password })
                setLoading(false)
                console.log('result', result)
                if (result.code == 0 ) {
                    if(result.data.isAdmin) {
                        navigate('/admin-manage-user', {replace: true})
                    } else if (result.data.needInitialize) {
                        navigate('/my-account', {replace: true})
                    } else {
                        navigate('/create-project', {replace: true})
                    }
                }
            } catch (e) {
                setLoading(false)
            }
          
        }

        const canLogin = email && password

        const showHint = () => {
            let hint = ''
            if (!email) {
                hint = 'Please enter your email'
            } else if (!password) {
                hint = 'Please enter your password'
            }
            if (hint) {
                message.warning(hint)
            }

        }

        return (
            <div className={styles.body}>
                <div className={styles.left}>
                    <div className={styles.logoGroup}>
                        <img src={logoLeft} className={styles.logoLeft} alt="logoLeft" />
                        <img src={logoRight} className={styles.logoRight} alt="logoRight" />
                    </div>
                    <div className={styles.leftContent}>
                        <div>LitMate, short for "Mate for reading and writing literature review," is an innovative, GenAI-powered platform designed to enhance students’ literature comprehension and writing skills through AI-generated feedback. This software guides users through a structured process that transitions from reading scholarly articles to crafting comprehensive literature reviews.</div><br />
                        <div>The platform consists of four integral components:</div><br />
                        <div className={styles.components}>
                            <div><b>1.Reference Annotating: </b>Students summarize research articles by focusing on the topic/aim, methodology, findings, and relevance to their research.</div>
                            <div><b>2.Reference Categorizing: </b>Students define their research themes and categorize cited articles, distinguishing between definitional and empirical literature for clearer understanding. </div>
                            <div><b>3.Reference Mapping: </b>Students create a mind map to visually organize the foundational logic of their literature review, illustrating connections between concepts.</div>
                            <div><b>4.Review Writing: </b>Finally, students synthesize insights from the previous steps to compose a coherent literature review.</div>
                        </div>
                        <br />
                        <div>By integrating these components, LitMate provides a comprehensive framework that supports students throughout the literature review process, ultimately fostering critical thinking and academic reading and writing skills.</div>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <div className={styles.right}>
                        <div className={styles.rightBox}>
                            <div className={styles.rightTitle}>Welcome to Log in LitMate</div>
                            <div className={styles.account}>
                                <img src={loginAccount} className={styles.accountImg} />
                                <input placeholder='Email' className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            </div>
                            <div className={styles.password}>
                                <img src={loginPsw} className={styles.pswImg} />
                                <input type="password" placeholder='Password' className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                            </div>
                            <div className={canLogin ? styles.buttonNormal : styles.buttonDisable} onClick={canLogin ? login : showHint}>
                                Log in
                            </div>
                            {/* <Button className={styles.loginButton} type="primary" size="large" disabled={!canLogin} onClick={canLogin ? login : showHint}>
                                Log in
                            </Button> */}
                            <div className={styles.termsBox}>
                                <a className={styles.terms} href='https://cosmic-porkpie-7f1.notion.site/LitMate-Development-Team-12b4a28a6890808fb4dce9ba9da8ef0b?pvs=4' target='_blank'>Development team</a>
                            </div>
                        </div>
                    </div>
                </Spin>
               

                <div className={styles.beian}>
                    <img src={beian} width="20" />
                    {/* <a className={styles.beianLink} target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34040002000383" >
                        <span>皖公网安备 34040002000383号</span>
                    </a> */}
                    <a className={styles.beianLink} target="_blank" href="https://beian.miit.gov.cn">
                        <span>粤ICP备2024327264号-1</span>
                    </a>
                </div>
            </div>
        )
    }
