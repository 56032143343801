import { createBrowserRouter } from 'react-router-dom';
import Root from './Root'
import Login from './Login';
import CreateProject from './CreateProject'
import ProjectDetail from './ProjectDetail';
import MyAccount from './MyAccount';
import DownloadPage from './DownloadPage';
// import ArticleDiscovery from './ArticleDiscovery'
// import InsightfulReview  from './InsightfulReview'
// import Main from './Main'
// import EditorDiscussion  from './EditorDiscussion'
// import Explor from './Explor'
// import ReflectionAndAdjustment  from './ReflectionAndAdjustment'
// import ViewUserDetail from './ViewUserDetail';
// import ViewUserDetailReading from './ViewUserDetailReading';
// import ManageUser from './ManageUser';
// import CRM from './CRM';
// import ViewUserData from './ViewUserData';
// import ViewUserDataReading from './ViewUserDataReading';
// import EditoralBoard from './EditoralBoard';

// 新系统页面
// import Entrance from './Entrance'
// import Achievement from './Achievement'
// import History from './History'
// import TaskHistory from './TaskHistory'
// import Level from './Level'

const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <h1>404 Not Found</h1>,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "my-account",
          element: <MyAccount />,
        },
        {
          path: "create-project",
          element: <CreateProject />,
        },
        
        {
          path: "project-detail/:id",
          element: <ProjectDetail />,
        },
        {
          path: "download-page/:id",
          element: <DownloadPage />,
        },
        // {
        //   path: "admin-manage-user",
        //   element: <ManageUser />,
        // },
        // {
        //   path: "admin-view-data",
        //   element: <ViewUserData />,
        // },
        // {
        //   path: "admin-view-data-reading",
        //   element: <ViewUserDataReading />,
        // },
        // {
        //   path: "view-user-detail",
        //   element: <ViewUserDetail />,
        // },
        // {
        //   path: "view-user-detail-reading",
        //   element: <ViewUserDetailReading />,
        // },
        // {
        //   path: "admin-crm",
        //   element: <CRM />,
        // },
      ],
    },
  ])

  export default router