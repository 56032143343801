import {Button, Col, Row, Space, Tooltip} from 'antd';
import React, {FC} from 'react';
import {SvgIcons} from './SvgIcons';

const Toolbar = () => {
  return (
    <Row gutter={16} className="mind-toolbar">
      <Col span={12}>
        <Space>
          <Tooltip title="重做">
            <Button icon={<SvgIcons.undo/>}></Button>
          </Tooltip>
          <Tooltip title="撤销">
            <Button icon={<SvgIcons.redo/>}></Button>
          </Tooltip>
          
        </Space>
      </Col>
      <Col span={4}></Col>
    </Row>
  );
};

export default Toolbar;