import React, { useRef } from 'react';
import { Button} from 'antd'
import { observer } from 'mobx-react';

import styles from '../css/AiHelps.module.scss'
import Robot from '../img/robot-avatar.png'

const AiHelps = (props) => {

    const { hideOperation, type, times, disabled, onClick, aiHelps, loading, showHint } = props

    // const [aiHelps, setAiHelps] = React.useState([])

    // useEffect(() => {
        
    //     setAiHelps(aiHelps)
    // }, [aiHelps])

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
        }, 100)
    }

    scrollToBottom()

    console.log(aiHelps, '!!')

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleCon}>
                <div className={styles.title}>LitMate Chatbot</div>
            </div>

            <div className={[styles.helps, hideOperation? styles.fullScreen : ''].join(' ')} ref={messagesEndRef} >
                {
                    aiHelps.map((item, index) => {
                        return (
                            <div key={index}>
                                {
                                    item.role === 'user' && 
                                    <div className={styles.chatRight}>
                                        <div className={styles.rightContentWithSmallMargin}>
                                            {item.showQuestion}
                                        </div>
                                    </div>
                                }
                                {
                                    (item.role === 'assistant' || item.role === 'system') &&
                                    <div className={styles.chatLeft}>
                                        <div className={styles.iconAndContent}>
                                            <div className={styles.leftAvatar} >
                                                <img src={Robot} className={styles.robot} />
                                            </div>
                                            <div className={styles.leftContentWithSmallMargin}>
                                                {item.content}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        
            <div className={styles.footer}>
                <Button type='default' size='large' loading={loading} disabled={disabled} onClick={onClick} className={styles.submit}>Provide feedback ({times})</Button>
                {showHint && <div className={styles.submitTxt}>Press the button at least once before submitting</div>}
            </div>

        </div>
    )
}

export default observer(AiHelps);