

import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import AiHelps from './AiHelps';
import styles from '../css/ProjectCorpus.module.scss';
import projectStore from '../store/projectStore';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import RefDetail from './RefDetail';
import { countWords } from '../utils/utils';

const ProjectCorpus = () => {

    const project = projectStore.project
    const refItems = project.projectRefs.map((item, index) => {
        return ({
            label: `Ref ${index + 1}`,
            children: <RefDetail projectRef={item} type='edit' disabled={project.isCompleted}/>,
            key: index.toString()
        })
    })

    const nextEnable = countWords(project.researchTopic) > 0
    && project.researchQuestions.every(question => countWords(question) > 0)
    && project.projectRefs.every(ref => !!ref.docUrl && !!ref.summary)
    && !project.isCompleted

    console.log(project)

    const aiEnabled = !!project.projectRefs[projectStore.refTabIndex].docUrl && !!project.projectRefs[projectStore.refTabIndex].summary && countWords(project.projectRefs[projectStore.refTabIndex].summary) > 0 
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.leftContaier}>
                        <div className={styles.leftTop}>
                        <div className={styles.researchTopic}>
                            Research topic：
                            <input placeholder='（Word limit of 20 words）' onChange={(e) => {
                                project.researchTopic = e.target.value
                            }} value={project.researchTopic}  disabled={project.step !== 0}/>
                        </div>
                        {
                            project.researchQuestions.map((_, index) => {
                                const questions = project.researchQuestions
                                return (
                                    <div className={styles.researchQuestion} key={index}>
                                        Research question {questions.length > 1 ? index + 1 : ''}：
                                        <input
                                            placeholder='（Word limit of 50 words）'
                                            onChange={(e) => {
                                                questions[index] = e.target.value
                                            }}
                                            disabled={project.isCompleted}
                                            value={questions[index]}
                                        />
                                        {
                                            index === questions.length - 1 && !project.isCompleted &&
                                            <div className={styles.addQuestionBtn}>
                                                <Button size='small' onClick={projectStore.addQuestion} icon={<PlusOutlined />}>Add a Research question</Button>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        </div>

                        <div className={styles.leftCenter}>
                            <Tabs
                                tabBarExtraContent={
                                    !project.isCompleted &&
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={projectStore.addRef}
                                        ghost
                                        type='primary'
                                        loading={projectStore.loading}
                                    >
                                    Add a Ref
                                    </Button>
                                }
                                items={refItems}
                                activeKey={projectStore.refTabIndex}
                                onChange={(key) => {
                                    projectStore.refTabIndex = key
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.rightContainer}>
                        <AiHelps 
                        hideOperation={false}
                        aiHelps={projectStore.project.refHelps}
                        times={projectStore.project.refLeft}
                        showHint                    
                        loading={projectStore.aiLoading}
                        onClick={projectStore.provideFeedback}
                        disabled={!aiEnabled}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerContainer}>
                <div className={styles.footerLeft}>
                    Reminder: Please ensure you review studies that offer definitions and empirical evidence related to your research concepts.
                </div>
                <Button 
                    disabled={!nextEnable} 
                    type='primary' 
                    size='large' 
                    onClick={projectStore.submitRefs}
                    className={styles.nextStep}
                    loading={projectStore.submitLoading}
                >Next step</Button>
                </div>
            </div>
        </div>
    )
}

export default observer(ProjectCorpus);