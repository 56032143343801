

import React, { useState } from 'react';
import { Button, Modal, Dropdown, Input } from 'antd';
import AiHelps from './AiHelps';
import styles from '../css/ProjectReview.module.scss';
import projectStore from '../store/projectStore';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { countWords } from '../utils/utils';
import DeleteIcon from '../img/delete-icon-gray.png';
import { useNavigate } from 'react-router';
import DeleteProject from '../img/delete-project.png'


const { TextArea } = Input;

const ProjectReview = () => {

    const project = projectStore.project
    const [confirmModal, setConfirmModal] = useState(false)
    const navigate = useNavigate()
    const nextEnable = project.moves.length > 0 && project.moves.every(move => countWords(move.content) > 0) && !project.isCompleted
    const aiEnabled = project.moves.length > 0 && project.moves.every(move => countWords(move.content) > 0) && !project.isCompleted

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.leftContaier}>
                        <div className={styles.leftTop}>
                        <div className={styles.researchTopic}>
                            Research topic：
                            {project.researchTopic}
                        </div>
                            {
                                project.researchQuestions.map((_, index) => {
                                    const questions = project.researchQuestions
                                    return (
                                        <div className={styles.researchQuestion} key={index}>
                                            Research question {questions.length > 1 ? index + 1 : ''}：
                                            {questions[index]}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.leftCenter}>
                            <div className={styles.leftContaierStep3}>
                                <div className={styles.step3Content}>
                                <div className={styles.reminder}>
                                    <div className={styles.title}>Reminder：3 moves in literature review </div>
                                    <div className={styles.move}>
                                    Move 1 – Establishing a research territory: by reviewing items of previous research in the area
                                    </div>
                                    <div className={styles.move}>
                                    Move 2 – Establishing a niche: by indicating a gap in the previous research or by extending previous knowledge in someway 
                                    </div>
                                    <div className={styles.move}>
                                    Move 3 – Occupying the niche: by outlining purposes or stating the nature of the present research
                                    </div>
                                </div>
                                <div className={styles.moveInput}>
                                    {
                                        project.moves.map((item, index) => {
                                        return (
                                                <div key={index} className={styles.moveInputItem}>
                                                    <div className={styles.inputContainer}>
                                                        <div className={styles.inputItem}>
                                                        <div className={styles.inputTitle}>Move {item.type}: </div>
                                                        <div className={styles.inputContent}>
                                                            <TextArea 
                                                                autoSize={{ minRows: 3, maxRows: 6 }}
                                                                className={styles.textarea}
                                                                onChange={(e) => item.content = e.target.value}
                                                                value={item.content}
                                                                bordered={false}
                                                                disabled={project.isCompleted}
                                                            />
                                                        </div>
                                                        <img src={DeleteIcon} className={styles.deleteInput} alt='delete' onClick={()=> {
                                                            if (project.isCompleted) return
                                                            projectStore.deleteMove(index)
                                                        }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                                <div className={styles.buttomBtn}>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                  key: '1',
                                                  label: 'Move 1',
                                                },{
                                                  key: '2',
                                                  label: 'Move 2',
                                                },{
                                                  key: '3',
                                                  label: 'Move 3',
                                                },
                                            ],
                                            onClick: (item) => {
                                                projectStore.addMove(item.key)
                                            }
                                        }}
                                        placement="top"
                                        trigger={['click']}
                                        disabled={project.isCompleted}
                                    >
                                        <Button type='primary' ghost icon={<PlusOutlined />}>Add a move</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.rightContainer}>
                        <AiHelps 
                        hideOperation={false}
                        aiHelps={projectStore.project.moveHelps}
                        times={projectStore.project.moveLeft}
                        showHint                    
                        loading={projectStore.aiLoading}
                        onClick={projectStore.askMoveHelp}
                        disabled={!aiEnabled}
                        />
                    </div>
                </div>
            </div>

            <Modal
                open={confirmModal}
                centered
                footer={null}
                maskClosable={true}
                closable={true}
                onCancel={() => setConfirmModal(false)}
                width={"46vw"}
                >
                <div className={styles.deleteModal}>
                    <div className={styles.modalContent}>
                    <img src={DeleteProject} className={styles.deleteProject} alt="deleteProject" />
                    <div className={styles.confrimText}>Kindly confirm your submission of the final version.</div>
                    </div>
                    <div className={styles.buttonGroup}>
                    <Button onClick={async () => {
                        await projectStore.submitMoves()
                        navigate('/download-page/' + project.id)
                    }} className={styles.yesBtn} type="primary">Yes</Button>
                    <Button onClick={() => {
                        setConfirmModal(false)
                    }} className={styles.noBtn} type="default">No</Button>
                    </div>
                </div>
            </Modal>
            
            <div className={styles.footer}>
                <div className={styles.footerContainer}>
                <div className={styles.footerLeft}>
                </div>
                <Button 
                    disabled={!nextEnable} 
                    type='primary' 
                    size='large' 
                    onClick={() => setConfirmModal(true)}
                    className={styles.nextStep}
                    loading={projectStore.submitLoading}
                >Final submission</Button>
                </div>
            </div>
        </div>
    )
}

export default observer(ProjectReview);