

import React, { useEffect } from 'react';
import { Button } from 'antd';
import AiHelps from './AiHelps';
import styles from '../css/ProjectMapping.module.scss';
import projectStore from '../store/projectStore';
import { observer } from 'mobx-react';
import MindMap from './MindMap';

const ProjectMapping = () => {

    const project = projectStore.project
    const disabled = project.step > 2

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.leftContaier}>
                        <MindMap
                            disabled={project.isCompleted}
                            rootName={project.title}
                            value={project.mindMapData}
                            onChange={(data) => {
                                projectStore.updateMindMap(data)
                            }}
                        />
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.rightContainer}>
                        <AiHelps 
                            hideOperation={false}
                            aiHelps={project.mindHelps}
                            times={project.mindLeft}
                            loading={projectStore.aiLoading}
                            showHint
                            onClick={projectStore.askMindHelp}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerContainer}>
                <div className={styles.footerLeft}>
                    Reminder: Please ensure your mind map of the literature review highlights areas that have not been thoroughly explored or questions that remain unanswered, thereby establishing the need for your study.
                </div>
                <Button 
                    type='primary' 
                    size='large' 
                    disabled={project.isCompleted}
                    onClick={projectStore.submitMindMap}
                    className={styles.nextStep}
                    loading={projectStore.submitLoading}
                >Next step</Button>
                </div>
            </div>
        </div>
    )
}

export default observer(ProjectMapping);