import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Spin, Modal } from 'antd';
import TabBar from './components/TabBar';
import styles from './css/CreateProject.module.scss';
import { useNavigate } from 'react-router';
import CreateIcon from './img/create-project.png';
import DeleteIcon from './img/delete-icon.png';
import ArrowRight from './img/arrow-right.png';
import FinishIcon from './img/finish-icon.png';
import DeleteProject from './img/delete-project.png'
import { createProject, getMyProjects, deleteMyProject } from './utils/request';
import dayjs from 'dayjs';

function CreateProject(props) {

  const [nameModalOpen, setNameModalOpen] = useState(false)
  const [deleteModal, setDeleteModalOpen] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [createLoading, setCreateLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [projectList, setProjectList] = useState([])

  const navigate = useNavigate()

  const showNameModal = () => {
    setNameModalOpen(true)
  }

  const changeProjectName = (e) => {
    setProjectName(e.target.value)
  }

  const saveProjectName = async () => {
    setCreateLoading(true)
    const result = await createProject({ title: projectName })
    setCreateLoading(false)
    setNameModalOpen(false)
    navigate('/project-detail/' + result.data.id)
    setProjectName('')
  }

  const deleteProject = async () => {
    setLoading(true)
    await deleteMyProject(deleteId)
    setLoading(false)
    fetchList()
    setDeleteModalOpen(false)
  }

  const handleRevise = (id) => {
    navigate('/project-detail/' + id)
  }

  const handleDownload = (id) => {
    navigate('/download-page/' + id)
  }

  const handleDelete = (id) => {
    setDeleteId(id)
    setDeleteModalOpen(true)
  } 

  useEffect(() => {
    fetchList()
  }, [])

  const fetchList = async () => {
    setLoading(true)
    const result = await getMyProjects()
    setLoading(false)
    setProjectList(result.data)
  }


  return (
    <div className={styles.page}>
      <TabBar />
      <div className={styles.container}>
        <Spin spinning={loading}>
          <div className={styles.projectGroup}>
            <div className={styles.createProject}  onClick={showNameModal}>
              <img src={CreateIcon} className={styles.createIcon} alt="createIcon" />
              <p>Start a new literature review project</p>
            </div>
            {
              projectList.map((project, index) => {
                return (
                  <div className={!project.isCompleted ? styles.reviseProject : styles.viewProject}>
                    <div className={styles.cardHeader}>
                      <div>Project {projectList.length - index}</div>
                      <img src={DeleteIcon} onClick={() => handleDelete(project.id)} className={styles.deleteIcon} alt="deleteIcon" />
                    </div>
                    <div className={styles.projectTitle}>{project.title}</div>
                    {
                      project.isCompleted && <img src={FinishIcon} className={styles.finishIcon} alt="finishIcon" />
                    }
                    {
                      !project.isCompleted ?
                      <Button
                        onClick={() => handleRevise(project.id)}
                        className={styles.selectBtn}
                        type="primary"
                      >
                        <div className={styles.selectBtnTxt}>
                          Revise
                        </div>
                        <img src={ArrowRight} className={styles.btnArrow} alt="btnArrow" />
                      </Button>
                      :
                      <div className={styles.btnContainer}>
                        <Button
                          onClick={() => handleRevise(project.id)}
                          className={styles.selectBtn}
                          type="default"
                        >View</Button>
                        <Button
                          onClick={() => handleDownload(project.id)}
                          className={styles.selectBtn2}
                          type="primary"
                        >Download</Button>
                      </div>
                    }
                    <div className={styles.createTime}>Creation time：{dayjs(project.createdAt).format('MMM D, YYYY')}</div>
                  </div>
                )
              })
            }
          </div>
          <div className={styles.description}>
          </div>
        </Spin>
      </div>

      <Modal
          open={nameModalOpen}
          centered
          footer={null}
          maskClosable={true}
          closable={true}
          // closeIcon={<div className={styles.modalClose}><img src={Close}/></div>}
          onCancel={() => {
            
            setNameModalOpen(false)
          }}
          width={"46vw"}
        >
          <div className={styles.projectModal}>
            <div className={styles.modalTitle}>Please enter your project name</div>
            <div className={styles.modalContent}>
              <input placeholder='Project name' className={styles.input} onChange={changeProjectName} value={projectName}/>
            </div>
            <Button onClick={() => saveProjectName()} loading={createLoading} className={styles.saveBtn} type="primary">Create</Button>
          </div>
      </Modal>

      <Modal
          open={deleteModal}
          centered
          footer={null}
          maskClosable={true}
          closable={true}
          // closeIcon={<div className={styles.modalClose}><img src={Close}/></div>}
          onCancel={() => setDeleteModalOpen(false)}
          width={"46vw"}
        >
          <div className={styles.deleteModal}>
            <div className={styles.modalContent}>
              <img src={DeleteProject} className={styles.deleteProject} alt="deleteProject" />
              <div className={styles.confrimText}>Are you sure to delete this project?<br />It cannot be recovered after deletion.</div>
            </div>
            <div className={styles.buttonGroup}>
              <Button onClick={deleteProject} className={styles.yesBtn} type="default">Yes</Button>
              <Button onClick={() => {
                setDeleteId();
                setDeleteModalOpen(false)
              }} className={styles.noBtn} type="primary">No</Button>
            </div>
          </div>
      </Modal>

    </div>
  )
}


export default observer(CreateProject);