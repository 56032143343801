import { observer } from 'mobx-react';
import  { Button, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download'
import { useEffect } from 'react';
import styles from './css/DownloadPage.module.scss';
import TabBar from './components/TabBar';
import projectStore from './store/projectStore';
import { useParams } from 'react-router';

function DownloadPage() {

  const download = () => {
    const downloadContent = projectStore.downloadContent
    fileDownload(downloadContent, `${projectStore.downloadTitle}.doc`)
  }

  const params = useParams()

  useEffect(() => {
    projectStore.fetchProjectDownload(params.id)
  }, [params.id])

  if (!projectStore.downloadContent || projectStore.loading) {
    return <div className={styles.spin}>
      <Spin tip="Loading" spinning />
    </div>
  }

  return (
    <div className={styles.page}>
      <TabBar />
      <div className={styles.body}>
        <div className={styles.container}>
          <div className={styles.title}>
            {projectStore.downloadTitle}
          </div>
          <div className={styles.content}>
            {projectStore.downloadContent}
          </div>
        </div>
      </div>
      <div className={styles.download}>
        <Button icon={<DownloadOutlined />} onClick={download}>download word</Button>
      </div>
    </div>
  );
}

export default observer(DownloadPage);
