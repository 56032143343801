import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import TabBar from './components/TabBar';
import ProjectSteps from './components/ProjectSteps';
import styles from './css/ProjectDetail.module.scss';

import projectStore from './store/projectStore';
import ProjectCorpus from './components/ProjectCorpus';
import ProjectCate from './components/ProjectCate';
import ProjectMapping from './components/ProjectMapping';
import ProjectReview from './components/ProjectReview';

function ProjectDetail() {
  const params = useParams()

  useEffect(() => {
    projectStore.fetchProjectDetail(params.id)
  }, [params.id])

  if (!projectStore.project) {
    return <div className={styles.spin}><Spin tip="Loading" spinning/></div>
  }

  return (
    <div className={styles.page}>
      <TabBar />
        <ProjectSteps />
        {
          projectStore.curStep === 0 && <ProjectCorpus />
        }
        {
          projectStore.curStep === 1 && <ProjectCate />
        }
        {
          projectStore.curStep === 2 && <ProjectMapping />
        }
        {
          projectStore.curStep === 3 && <ProjectReview />
        }
    </div>
  )
}

export default observer(ProjectDetail);